import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FlexCard from '../components/flexcard';
import GiftCardBackground from '../components/giftcardbackground';
import settings from '../../settings';
import BmwGiftCardGlance from '../components/bmwGlance/bmwGiftCardGlance';

// images
const topbanner = `${settings.IMAGES_BASE_URL}/images/giftcards/gift-card-banner.jpg`;
const giftcardimage = `${settings.IMAGES_BASE_URL}/images/giftcards/gift-card.jpg`;
const giftcardimagenew = `${settings.IMAGES_BASE_URL}/images/giftcards/gift-card-new.jpg`;
const indySaleImg = `${settings.IMAGES_BASE_URL}/images/specialoffers/bmw-indy-sale.jpg`;
const today = new Date();
const showFathersDayPromotion = 
  today >= new Date(2023,4,16) && 
  today < new Date(2023,6,1);

const GiftCards = () => {
  const getFirstCard = () => {
    if (showFathersDayPromotion) {
      return (
        <FlexCard
          rowType="row"
          image={indySaleImg}
          imageAlt="CELEBRATE WITH SPEED"
          header="CELEBRATE WITH SPEED"
          subheader="GET 20% OFF A GIFT CARD"
          text="Celebrate dads and grads with 20% off a gift card. They are good towards many classes and Experiences, including our newest location at the Indianapolis Motor Speedway."
          btnText="Call 888-345-4269 to order"
          btnStyle="btn-blue-offers"
          phone="tel:888-345-4269"
        >
          <p>
            Call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a> to order yours today. Use promo code <span className="bold">23BHP63020</span>. 
            Must purchase gift card by 6/30/23. M4 GT4 Experience, Race License School and Private Instruction excluded.
          </p>
        </FlexCard>
      )
    } else {
      return (
        <FlexCard
          rowType="row"
          image={giftcardimagenew}
          imageAlt="Give the gift of speed"
          header="Give the gift of speed"
          subheader="THE ULTIMATE OF BMW DRIVER GIFTS"
          text="Give someone a driving gift they’ll never forget. Good toward any class, each BMW gift card is delivered electronically, so it’s also perfect for last-minute gift giving."
          btnText="Call 888-345-4269 to purchase"
          btnStyle="btn-blue"
          externalURL="tel:888-345-4269"
        >
        </FlexCard>
      )
    }
  }

  return (
    <Layout>
      <SEO title="BMW Gift Cards | BMW Performance Driving School" description="Give the gift of speed with the Ultimate of BMW Driver Gifts." />
      <div className="giftcardpage giftcardpagebmw">
          <GiftCardBackground
            imageDesktop={topbanner}
            imageMobile={topbanner}
            imageAlt="BMW Gift Card Top Banner"
          />

          <section className="performance-center">
            <div className="container">
              <div className="performance-center__header">
                <h2>Bmw Gift Cards</h2>
              </div>
            </div>
            {getFirstCard()}
          </section>
          <BmwGiftCardGlance/>
        </div>
    </Layout>
  );
};

export default GiftCards;
